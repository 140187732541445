import { getToken, removeToken } from '@utils';
import axios, { AxiosError, InternalAxiosRequestConfig } from 'axios';

export const apiInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL || '',
  headers: {
    Accept: 'application/json',
  },
});

apiInstance.interceptors.request.use(
  (config) => {
    const token = getToken();
    if (!token) return config;

    const newConfig = {
      ...config,
      headers: {
        ...config.headers,
        Authorization: `Bearer ${token}`,
      },
    } as InternalAxiosRequestConfig<any>;
    return newConfig;
  },
  (error) => {
    return Promise.reject(error);
  }
);

apiInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error: AxiosError) => {
    if (error.response?.status === 401) {
      removeToken();
      window.location.href = '/auth';
    }
    return Promise.reject(error);
  }
);