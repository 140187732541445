import { getToken } from "@utils";
import AdminLayout from "layouts/admin";
import AuthLayout from "layouts/auth";
import { Suspense } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { renderRoutes } from "routes";

const App = () => {
  const token = getToken();
  return (
    <Routes>
      <Route path="auth/*" element={<AuthLayout />} />
      <Route
        path="admin/*"
        element={
          <Suspense>
            <AdminLayout />
          </Suspense>
        }
      >
        <Route path="*" element={<Navigate to="users" />} />
        {renderRoutes()}
      </Route>
      <Route path="*" element={<Navigate to={token ? "/admin" : "/auth"} />} />
    </Routes>
  );
};

export default App;
