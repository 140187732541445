import { FixedPlugin } from "@components";
import { Routes, Route, Navigate } from "react-router-dom";
import { SignIn } from "views";

export default function Auth() {
  return (
    <div>
      <div className="relative float-right h-full min-h-screen w-full !bg-white dark:!bg-navy-900">
        <FixedPlugin />
        <main className={`mx-auto min-h-screen`}>
          <div className="relative flex">
            <Routes>
              <Route
                path="/"
                element={<Navigate to="/auth/sign-in" replace />}
              />
              <Route path="/sign-in" element={<SignIn />} />
            </Routes>
          </div>
        </main>
      </div>
    </div>
  );
}
