import { Button, FormControl, FormLabel, Stack, Text } from "@chakra-ui/react";
import { IoEyeOutline, IoEyeOffOutline } from "react-icons/io5";
import { InputField } from "@components";
import { getToken, setToken } from "@utils";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { signInAdmin } from "services";
import { IFormSignInAdmin } from "types";

export const SignIn = () => {
  const navigate = useNavigate();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isValid, setIsValid] = useState(true);
  const [loginForm, setLoginForm] = useState<IFormSignInAdmin>({
    username: "",
    password: "",
  });
  const [showPassword, setShowPassword] = useState(false);

  useEffect(() => {
    if (loginForm.username && loginForm.password) setIsValid(true);
  }, [loginForm]);

  useEffect(() => {
    const token = getToken();
    if (token) {
      navigate("/admin");
    }
  }, []);

  const handleSignIn = async () => {
    if (!loginForm.username || !loginForm.password) return setIsValid(false);
    try {
      setIsSubmitting(true);
      const token = await signInAdmin(loginForm);
      setToken(token);
      navigate("/admin");
    } catch (error) {
      setIsValid(false);
      console.log("log-error --- ", error);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="mb-16 mt-16 flex h-full w-full items-center justify-center px-4 md:mx-0 md:px-6 lg:mb-10 lg:items-center lg:justify-center">
      <div className="mt-[10vh] w-full max-w-full flex-col items-center lg:pl-0 xl:max-w-[420px]">
        <h4 className="mb-2.5 text-4xl font-bold text-navy-700 dark:text-white">
          Sign In
        </h4>
        <p className="mb-9 ml-1 text-base text-gray-600">
          Enter your username and password to sign in!
        </p>
        <form 
          onSubmit={(e) => {
            e.preventDefault();
            handleSignIn();
          }}
        >
          <Stack spacing={4}>
            <FormControl>
              <FormLabel className="dark:text-white" htmlFor="username">
                Username*
              </FormLabel>
              <InputField
                id="username"
                type="username"
                value={loginForm.username}
                placeholder="Enter your username"
                className="bg-white dark:bg-navy-600 dark:text-white"
                disabled={isSubmitting}
                onChange={(e) =>
                  setLoginForm({ ...loginForm, username: e.target.value })
                }
              />
            </FormControl>
            <FormControl>
              <FormLabel className="dark:text-white" htmlFor="password">
                Password*
              </FormLabel>
              <InputField
                id="password"
                type={showPassword ? 'text' : 'password'}
                placeholder="Enter your password"
                className="bg-white dark:bg-navy-600 dark:text-white"
                disabled={isSubmitting}
                value={loginForm.password}
                onChange={(e) =>
                  setLoginForm({ ...loginForm, password: e.target.value })
                }
                iconRight={
                  showPassword ? (
                    <IoEyeOffOutline
                      size={25}
                      className="cursor-pointer"
                      onClick={() => setShowPassword(!showPassword)}
                    />
                  ) : (
                    <IoEyeOutline
                      size={25}
                      className="cursor-pointer"
                      onClick={() => setShowPassword(!showPassword)}
                    />
                  )
                }
              />
            </FormControl>
            {!isValid && (
              <Text color="red.500">
                The username or password you entered is incorrect
              </Text>
            )}
          </Stack>
          <Button
            isLoading={isSubmitting}
            variant="brandPrimary"
            className="mt-5 w-full bg-brand-500 text-base font-medium text-white hover:bg-brand-600 disabled:bg-gray-300 disabled:text-gray-900 dark:bg-white dark:text-navy-600 dark:hover:bg-navy-600 dark:hover:text-white dark:disabled:hover:bg-gray-300 dark:disabled:hover:text-gray-900"
            type="submit"
            isDisabled={isSubmitting || !isValid}
          >
            Sign in
          </Button>
        </form>
      </div>
    </div>
  );
};