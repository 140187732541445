const LOCAL_STORAGE_KEY = "accessToken";

export const getToken = () => localStorage.getItem(LOCAL_STORAGE_KEY);

export const setToken = (token: string) => {
  localStorage.setItem(LOCAL_STORAGE_KEY, token);
};

export const removeToken = () => {
  localStorage.removeItem(LOCAL_STORAGE_KEY);
};

export const createRandomUUID = () => {
  const timestamp = Math.floor(Date.now() / 1000).toString(16);
  const randomValue = Array.from({length: 10}, () => Math.floor(Math.random() * 16).toString(16)).join('');
  const counter = Array.from({length: 6}, () => Math.floor(Math.random() * 16).toString(16)).join('');
  return timestamp + randomValue + counter;
};