import { useToast } from "@chakra-ui/react";
import {
  EToastStatus,
  dateParamsQuery
} from "enums";
import moment from "moment";
import { createContext, useMemo, useState } from "react";
import { IFilterDate, IGlobalContext } from "types";

export const AdminContext = createContext<IGlobalContext>({} as IGlobalContext);

export const AdminContextProvider = ({ children }: { children: React.ReactNode }) => {
  const [isFetching, setIsFetching] = useState(false);
  const [email, setEmail] = useState("");
  const [filterDate, setFilterDate] = useState<IFilterDate>(dateParamsQuery);
  const toast = useToast();

  const currentTimezone = useMemo(() => {
    const localTimezone = moment.tz.guess();
    const timezone = moment.tz.zone(localTimezone);
    const currentTimezoneName = timezone?.name || '';
    const offsetInMinutes = moment.tz(localTimezone).utcOffset();
    const offsetHours = Math.abs(Math.floor(offsetInMinutes / 60));
    const offsetMinutes = Math.abs(offsetInMinutes % 60);
    const offsetSign = offsetInMinutes >= 0 ? '+' : '-';
    const offsetString = `UTC${offsetSign}${offsetHours}:${offsetMinutes.toString().padStart(2, '0')}`;
    return { name: currentTimezoneName, timezone: offsetString };
  }, []);

  const showToast = (
    title: React.ReactNode,
    description: React.ReactNode,
    status: EToastStatus
  ) => {
    toast({
      title,
      description,
      status,
      position: "top-right",
      duration: 3000,
      isClosable: true,
    });
  };

  return (
    <AdminContext.Provider
      value={{
        email,
        setEmail,
        showToast,
        isFetching,
        setIsFetching,
        filterDate,
        setFilterDate,
        currentTimezone
      }}
    >
      {children}
    </AdminContext.Provider>
  );
};
